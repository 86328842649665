import React from "react"
import { Form, Col, Row, Button, Modal, Spinner } from 'react-bootstrap'
import _ from 'lodash'
import { provinceList } from './../assets/address'
import Message from './MessageTip'
import './media.css'
import './common.css'

import { data } from '../assets/trade.json'
import { apiURL, baseURL, getUrlParms } from "./census"

const CustomerInfosLink = props => {
  const [validated, setValidated] = React.useState(false)
  const [none, setNone] = React.useState(false)
  const [validations, setVaildations] = React.useState()
  const [changeSite, setChangeSite] = React.useState(false)
  const [show, setShow] = React.useState(false)
  const [citySelect, setCitySelect] = React.useState([])
  const [proviceCity, setProviceCity] = React.useState({ provice: false, city: false })
  const [enterprise, setEnterprise] = React.useState(false)
  const [message, setMessage] = React.useState({ show: false, msg: null, status: false })

  const [disabled, setDisabled] = React.useState(false)

  const radioRef = React.useRef()

  let priceflag = getUrlParms('type')
  const machineCode = getUrlParms('machineCode')

  let defaultValue = {
    company_name: null,
    industry: null,
    post: null,
    contact_name: null,
    phone_number: null,
    machineCode: machineCode || null,
    usefulness: null,
    // claimed_location: null,
    contactBroker: true,
    province: null,
    city: null,
    email: null,
    user_flag: '申请试用',
    channel: [false, false, false, false, false, false, false, false, false, false],
    radio: [false, false, false, false],
    // usefulness: [ false, false, false ],
    description: null
  }

  let checkList = ['百度搜索', '媒体报道', '展会活动', '直播公开课', '行业社群', '朋友介绍', '抖音广告', '头条广告', '大学计划']

  let radioList = ['产品合作', '调研学习', '案例模版订购', '平台采购', '培训报名']

  let tgRadioList = ['自用', '做项目', '选型调研']

  let enterpriseData = ['是', '否']

  let formTitle, formDesc, formMessage, formBg;

  switch (priceflag) {
    case 'license':
      checkList = checkList
      defaultValue.channel = defaultValue.channel
      formTitle = '提交申请验证成功后，授权文件将会自动下载，请及时查看'
      formDesc = '申请试用'
      formMessage = '您是如何了解到AIRIOT的？（多选）'
      formBg = `url(${require("./../images/start1.jpeg")}) no-repeat 100% 100%`
      break;
    case 'price':
      checkList = ['高级版', '基础版', '标准版', '定制咨询']
      defaultValue.channel = [false, false, false, false]
      formTitle = '请填写信息，客户经理将致电您办理套餐包定购事宜'
      formDesc = '立即定购'
      formMessage = '定购套餐类型：（多选）'
      formBg = `url(${require("./../images/start1.jpeg")}) no-repeat 100% 100%`
      break;
    case 'ecology':
      checkList = ['业务生态联盟', '智慧物联商城', '训练营认证培训', '行业专家智库']
      defaultValue.channel = [false, false, false, false]
      formTitle = '请完善信息，提交成功后快速加入AIRIOT智慧物联生态圈'
      formDesc = '马上加入'
      formMessage = '想加入的生态模式：（多选）'
      formBg = `url(${require("./../images/ecologyenter.jpeg")}) no-repeat 100% 100%`
      break;
    case 'tg':
      checkList = ['百度搜索', '媒体报道', '展会活动', '直播公开课', '行业社群', '朋友介绍']
      defaultValue.channel = defaultValue.channel
      formTitle = '请完善信息，试用申请提交成功后您可快速体验AIRIOT'
      formDesc = '申请试用'
      formMessage = '您是如何了解到AIRIOT的？（多选）'
      formBg = `url(${require("./../images/WechatIMG492.png")}) no-repeat 100% 100%`
      break;
    default:
      checkList = checkList
      defaultValue.channel = defaultValue.channel
      formTitle = '请完善信息，试用申请提交成功后您可快速体验AIRIOT'
      formDesc = '申请试用'
      formMessage = '您是如何了解到AIRIOT的？（多选）'
      formBg = `url(${require("./../images/start1.jpeg")}) no-repeat 100% 100%`
      break;
  }

  if (props?.type == 'down') {
    formTitle = '如果您是第一次访问我们，请先完成注册以便我们与您联系完成下载授权'
    formDesc = ''
    checkList = ['百度搜索', '媒体报道', '展会活动', '直播公开课', '朋友介绍', '抖音广告']
  }

  const [inputData, setInputData] = React.useState(defaultValue)

  const saveCustomerInformation = (newInputData, inputData) => {
    fetch(`${apiURL}/customer-infos`, {
      headers: { 'content-type': 'application/json' },
      method: "post",
      body: JSON.stringify(newInputData)
    })
      .then((res) => {
        return res.json()
      })
      .then(data => {
        if (data.status == '成功') {
          //设置cookie
          document.cookie = `data=${JSON.stringify(inputData)}`
          if (props.type == 'down') {
            props.changeModal(false)
            window.location.href = props.url
          }
          if (props?.type == 'ppt-down') {
            props.changeModal(false)
            props.downloadUrlFile(props?.url, props?.name)
          }
          // if(priceflag != 'license' || props?.type != 'down') { setShow(true) } else { setShow(false) }
          setDisabled(false)
          if (priceflag == 'license' || props?.type == 'down') { setShow(false) } else { setShow(true) }
        } else {
          setShow(false)
          setDisabled(false)
          if (priceflag != 'license') setMessage({ show: true, msg: data?.errMsg, status: false })
        }
      })
      .catch(err => { console.log(err); setShow(false) })
  }

  const handleSubmit = (event) => {

    event.preventDefault(); // 阻止默认的提交的页面跳转行为
    event.stopPropagation();
    const [isValid, validation] = checkValidation()
    setVaildations(validation)
    setValidated(!isValid)
    let source = window?.location?.search && window.location.search.substr(window.location.search.indexOf('?') + 1)
    if (isValid) {
      setDisabled(true)
      let newInputData = {
        ...inputData,
        type: props?.type == 'down' ? '下载页' : source ? source : '首页',
        channel: inputData.channel.map((item, index) => { if (item) { return checkList[index] } }).filter(item => item).join(","),
        description: inputData.radio.map((item, index) => { if (item) { return radioList[index] } }).filter(item => item).join(","),
        // usefulness:  inputData.usefulness.map((item,index) => { if(item) { return tgRadioList[index] } }).filter(item=> item).join(","),
        claimed_location: `${inputData?.province}${inputData?.city}`,
        contactBroker: inputData.contactBroker ? '是' : '否'
      }
      delete newInputData.radio
      if (_.isEmpty(newInputData?.industry)) {
        delete newInputData.industry
      }
      if (priceflag == 'tg') {
        newInputData.description = '免费使用'
      }
      if (priceflag == 'license') {
        fetch(`${baseURL}/trial/apply`, {
          headers: { 'content-type': 'application/json' },
          method: "post",
          body: JSON.stringify(newInputData)
        })
          .then((res) => {
            return res.json()
          })
          .then(data => {
            if (data?.code == 200) {
              const url = data?.data?.url
              //判断机器码是否存在 (不存在下载授权)
              if (url) {
                saveCustomerInformation(newInputData, inputData)
                //下载授权文件
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', '授权文件')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                setMessage({ show: true, msg: data?.message, status: true })
              }
              setDisabled(false)
            } else if (data?.code == 400) {
              // setShow(true)
              setDisabled(false)
              setMessage({ show: true, msg: data?.message, status: false })
            }
          })
          .catch(err => console.log(err))
      } else {
        delete newInputData.contactBroker
        saveCustomerInformation(newInputData, inputData)
      }
    }
  }

  const checkValidation = () => {
    let validation = {};
    let regEmail = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/
    let reg = /^[1]([3-9])[0-9]{9}$/
    let regName = /^[a-zA-Z\u4e00-\u9fa5]+$/

    if (inputData.company_name === null) {
      validation.company_name = { status: false, msg: '公司名称不能为空' }
      return [false, validation]
    } else if (!(regName.test(inputData.company_name))) {
      validation.company_name = { status: false, msg: '公司名称只能输入中文或英文' }
      return [false, validation]
    } else {
      validation.company_name = { status: true }
    }



    if (priceflag && ['price', 'ecology', 'license'].indexOf(priceflag) < 0) {
      if (inputData.post === null) {
        validation.post = { status: false, msg: '职位不能为空' }
        return [false, validation]
      } else {
        validation.post = { status: true }
      }
    }

    if (priceflag && ['license'].indexOf(priceflag) > -1) {
      // if (inputData.email === null) {
      //   validation.email = { status: false, msg: '邮箱不能为空' }
      //   return [false, validation]
      // } else if(!(regEmail.test(inputData.email))) {
      //   validation.email = { status: false, msg: '邮箱输入不规范' }
      //   return [false, validation]
      // } else {
      //   validation.email = { status: true }
      // }

      if (inputData.machineCode === null) {
        validation.machineCode = { status: false, msg: '机器码不能为空' }
        return [false, validation]
      } else {
        validation.machineCode = { status: true }
      }

    }

    // if (inputData.contactBroker === null) {
    //   validation.contactBroker = { status: false, msg: '是否需要客户经理联系不能为空' }
    //   return [false, validation]
    // } else {
    //   validation.contactBroker = { status: true }
    // }

    if (inputData.contact_name === null) {
      validation.contact_name = { status: false, msg: '用户姓名不能为空' }
      return [false, validation]
    } else if (!(regName.test(inputData.contact_name))) {
      validation.contact_name = { status: false, msg: '用户姓名只能输入中文或英文' }
      return [false, validation]
    } else {
      validation.contact_name = { status: true }
    }



    if (inputData.phone_number === null) {
      validation.phone_number = { status: false, msg: '手机号不能为空' }
      return [false, validation]
    } else if (!(reg.test(inputData.phone_number))) {
      validation.phone_number = { status: false, msg: '手机号输入不规范' }
      return [false, validation]
    } else {
      validation.phone_number = { status: true }
    }

    if (inputData.province === null) {
      validation.province = { status: false, msg: '省份不能为空' }
      return [false, validation]
    } else {
      validation.province = { status: true }
    }
    if (inputData.city === null) {
      validation.city = { status: false, msg: '城市不能为空' }
      return [false, validation]
    } else {
      validation.city = { status: true }
    }

    if (priceflag && ['license'].indexOf(priceflag) == -1) {

      if (inputData.channel.filter(item => item == true)?.length == 0) {
        validation.channel = { status: false, msg: '了解渠道不能为空' }
        return [false, validation]
      } else {
        validation.channel = { status: true }
      }

      if (priceflag && ['tg'].indexOf(priceflag) < 0) {
        if (inputData.radio.filter(item => item == true)?.length == 0) {
          validation.description = { status: false, msg: '需求描述不能为空' }
          return [false, validation]
        } else {
          validation.description = { status: true }
        }
      }

      if (priceflag && ['price', 'ecology'].indexOf(priceflag) > -1) {
        if (inputData.industry === null) {
          validation.industry = { status: false, msg: '所属行业不能为空' }
          return [false, validation]
        } else {
          validation.industry = { status: true }
        }
      }

    }

    return [true, validation]
  }

  const onInputChange = (e, index) => {
    const { name, value } = e.target
    if (name == 'channel') {
      setInputData({ ...inputData, channel: inputData.channel.map((item, i) => { if (i == index) { return e.target.checked } return item }) })
    } else if (name == 'description') {
      setInputData({ ...inputData, radio: inputData.radio.map((item, i) => { if (i == index) { return true } else { return false } }) })
    }
    // else if(name == 'usefulness') {
    //   setInputData({ ...inputData, usefulness: inputData.usefulness.map((item, i) => { if(i == index) { return true } else { return false } }) })
    // } 
    else if (name === 'contactBroker') {
      setEnterprise(!inputData.contactBroker)
      setInputData({ ...inputData, [name]: !inputData.contactBroker })
    } else {
      setInputData({ ...inputData, [name]: value })
    }
    if (name === 'industry') setNone(true)
  }

  const onChangeProvince = (e, index) => {
    const { name, value } = e.target
    if (name === 'provice') setProviceCity({ ...proviceCity, provice: true })
    if (value) {
      let city = provinceList.filter(item => item.name == value)[0]?.cityList
      setCitySelect(city)
      setInputData({ ...inputData, [name]: value, city: city?.[0]?.name })
    }
  }

  const onChangeCity = (e, index) => {
    const { name, value } = e.target
    if (name === 'city') setProviceCity({ ...proviceCity, city: true })
    setInputData({ ...inputData, [name]: value })
  }

  let margin = props?.type == 'down' ? '1rem auto' : '10rem 0 3rem'
  let marginBottom = props?.type == 'down' ? '1rem' : '7.5rem'

  const downStyle = {
    background: '#007bff',
    border: '1px solid #007bff',
    borderRadius: '2.5rem',
    padding: '0.5rem 3rem',
    marginBottom: '2rem',
    fontSize: '1.875rem',
    width: '18.75rem',
    height: '3.75rem'
  }
  const handleClick = () => {
    window.open("https://r8ja3mlg7i.feishu.cn/share/base/form/shrcn6Ns5Vk4fgiEanpunuN71Ve", '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <div style={{ width: props?.type == 'down' ? '62%' : "100%", textAlign: 'center', margin, fontSize: '1.5rem', color: '#373E98', fontFamily: 'MicrosoftYaHei' }}>
        <div><Button style={props?.type == 'down' ? downStyle : { display: 'none' }} onClick={() => {
          window.location.href = props.url
        }}>立即下载</Button></div>
        <div style={{ fontSize: props?.type == 'down' ? '1rem' : '1.5rem' }}>{formTitle}</div>
      </div>
      <div className="sub-box" style={{ color: 'white' }}>
        <Button
          onClick={handleClick}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '8.125rem', height: '3rem', lineHeight: '3rem', textAlign: 'center', padding: 0, margin: '2rem auto 0' }}
          variant="primary" className="submit">
          {disabled ? <Spinner style={{ marginRight: 10, width: '1.5rem', height: '1.5rem' }} animation="border" /> : null}
          联系我们
        </Button>
      </div>

    </>
  )
}

export default CustomerInfosLink
